<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
          <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          Quality Control Pemda Provinsi
        </h3>
        <div class="card-toolbar"></div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::Item-->
        <div class="mb-12">
          <!--begin::Content-->
          <div>
            <div style="overflow-x: auto">
              <table
                id="example"
                class="table table-striped table-bordered"
                style="border-collapse: collapse; width: 100%"
              >
                <thead>
                  <tr>
                    <th rowspan="2">No</th>
                    <th rowspan="2">Nama Provinsi</th>
                    <th colspan="5" style="text-align: center">
                      Penilaian Tahun 2022
                    </th>
                    <th colspan="5" style="text-align: center">
                      Penilaian Tahun 2023
                    </th>
                    <th colspan="5" style="text-align: center">
                      Penilaian Tahun 2024
                    </th>
                    <th rowspan="2" style="text-align: center">
                      Perubahan Predikat 2022 - 2023
                    </th>
                    <th rowspan="2" style="text-align: center">
                      Perubahan Predikat 2023 - 2024
                    </th>

                    <th style="text-align: center" colspan="4">
                      Quality Control
                    </th>
                    <th rowspan="2">Nilai Akhir</th>
                    <th style="text-align: center" colspan="2">File BA QC</th>
                    <th style="text-align: center" colspan="2">Aksi</th>
                  </tr>
                  <tr>
                    <th>PM PTSP</th>
                    <th>Penilaian Organisasi Pengusaha</th>
                    <th>Total Penilaian PTSP</th>
                    <th>PM PPB</th>
                    <th>Total Akhir</th>

                    <th>PM PTSP</th>
                    <th>Penilaian Organisasi Pengusaha</th>
                    <th>Total Penilaian PTSP</th>
                    <th>PM PPB</th>
                    <th>Total Akhir</th>

                    <th>PM PTSP</th>
                    <th>Penilaian Organisasi Pengusaha</th>
                    <th>Total Penilaian PTSP</th>
                    <th>PM PPB</th>
                    <th>Total Akhir</th>

                    <th>PM PTSP</th>
                    <th>Penilaian Organisasi Pengusaha</th>
                    <th>Total Penilaian PTSP</th>
                    <th>PM PPB</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>

                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in totskor" :key="index">
                    <td>{{ index+1 }}</td>
                    <td>
                      <div style="width: 150px">{{ row.daerah }}</div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.data_2023.total_pm_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.data_2023.total_asosiasi_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.data_2023.total_ptsp_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.data_2023.total_ppb_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.data_2023.total_akhir_past) }}
                      </div>
                    </td>

                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_pm_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_asosiasi_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.total_ptsp_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_ppb_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.total_akhir_past) }}
                      </div>
                    </td>

                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_pm_curr) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_asosiasi_curr) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.total_ptsp_curr) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_ppb_curr) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.total_akhir_curr) }}
                      </div>
                    </td>

                    <td>
                      <b-badge
                        v-if="row.data_2023.status_perubahan == 'Naik'"
                        variant="success"
                        >{{ row.data_2023.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.data_2023.status_perubahan == 'Sama'"
                        class="text-white"
                        style="background-color: dimgray"
                        >{{ row.data_2023.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.data_2023.status_perubahan == 'Turun'"
                        variant="danger"
                        >{{ row.data_2023.status_perubahan }}</b-badge
                      >
                    </td>

                    <td>
                      <b-badge
                        v-if="row.status_perubahan == 'Naik'"
                        variant="success"
                        >{{ row.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.status_perubahan == 'Sama'"
                        class="text-white"
                        style="background-color: dimgray"
                        >{{ row.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.status_perubahan == 'Turun'"
                        variant="danger"
                        >{{ row.status_perubahan }}</b-badge
                      >
                    </td>

                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_pm) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_asosiasi) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.total_ptsp) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 120px">
                        {{ format_desimal(row.total_ppb) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 80px">
                        {{ format_desimal(row.total_akhir) }}
                      </div>
                    </td>

                    <td>
                      <div
                        style="width: 120px"
                        v-if="
                          row.file_ba_ptsp == null || row.file_ba_ptsp === ''
                        "
                      ></div>
                      <div v-else>
                        <a
                          :href="row.file_ba_ptsp"
                          target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"
                          ><i class="fa fa-download" aria-hidden="true"
                        /></a>
                      </div>
                    </td>
                    <td>
                      <div
                        style="width: 120px"
                        v-if="row.file_ba_ppb == null || row.file_ba_ppb === ''"
                      ></div>
                      <div v-else>
                        <a
                          :href="row.file_ba_ppb"
                          target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"
                          ><i class="fa fa-download" aria-hidden="true"
                        /></a>
                      </div>
                    </td>

                    <td>
                      <b-button
                        variant="primary"
                        class="m-1"
                        @click="qualityControl(row.idptsp, row.daerah)"
                        v-if="
                          user.id_role === 'qc' &&
                          (row.file_ba_ptsp == null || row.file_ba_ptsp === '')
                        "
                        >QC PM PTSP</b-button
                      >
                      <b-button
                        variant="primary"
                        class="m-1"
                        @click="qualityControl(row.idptsp, row.daerah)"
                        v-else-if="
                          user.id_role === 'qc' &&
                          (row.file_ba_ptsp != null || row.file_ba_ptsp != '')
                        "
                        disabled
                        >QC PM PTSP</b-button
                      >
                      <b-button
                        variant="primary"
                        class="m-1"
                        @click="qualityControl(row.idptsp, row.daerah)"
                        v-else-if="
                          user.id_role === 'pendamping_qc' ||
                          user.id_role === 'super_user' ||
                          user.id_role === 'helpdesk' || user.id_role === 'dpb'
                        "
                        >Lihat</b-button
                      >
                    </td>
                    <td>
                      <b-button
                        variant="primary"
                        class="m-1"
                        @click="qualityControlPPB(row.idptsp, row.daerah)"
                        v-if="
                          user.id_role === 'qc' &&
                          (row.file_ba_ppb == null || row.file_ba_ppb === '')
                        "
                      >
                        QC PM PPB</b-button
                      >
                      <b-button
                        variant="primary"
                        class="m-1"
                        @click="qualityControlPPB(row.idptsp, row.daerah)"
                        v-else-if="
                          user.id_role === 'qc' &&
                          (row.file_ba_ppb != null || row.file_ba_ppb != '')
                        "
                        disabled
                        >QC PM PPB</b-button
                      >
                      <b-button
                        variant="primary"
                        class="m-1"
                        @click="qualityControlPPB(row.idptsp, row.daerah)"
                        v-else-if="
                          user.id_role === 'pendamping_qc' ||
                          user.id_role === 'super_user' ||
                          user.id_role === 'helpdesk' || user.id_role === 'dpb'
                        "
                        >Lihat</b-button
                      >
                    </td>

                    <!-- <td></td> -->
                    <!-- <td>t.nixon@datatables.net</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>
  </div>
  <!-- </div>
    </div> -->
</template>
<style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      test: "hai",
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      totskor: [],
      search: "",
      headers: [
        {
          text: "Nama",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Ket", value: "ket" },
        { text: "Icon", value: "icon" },
        { text: "Aksi", value: "" },
      ],
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Quality Control" }]);
    this.loaddata();
  },
  methods: {
    qualityControl(idptsp, nama_wilayah) {
      // localStorage.setItem("idptsp", idptsp);
      // localStorage.setItem("nama_wilayah", nama_wilayah);
      localStorage.setItem("jenis", "provinsi");
      if (this.user.id_role === "qc") {
        this.$router.push("/qualitycontrol/qc-pmptsp/"+idptsp);
      } else if (this.user.id_role === "pendamping_qc") {
        this.$router.push("/qualitycontrol/detail-qc-pmptsp/"+idptsp);
      } else if (
        this.user.id_role === "super_user" ||
        this.user.id_role === "helpdesk" || this.user.id_role === "dpb"
      ) {
        this.$router.push("/qualitycontrol/detail-qc-pmptsp/"+idptsp);
      }
    },
    qualityControlPPB(idptsp, nama_wilayah) {
      // localStorage.setItem("idptsp", idptsp);
      // localStorage.setItem("nama_wilayah", nama_wilayah);
      localStorage.setItem("jenis", "provinsi");
      if (this.user.id_role === "qc") {
        this.$router.push("/qualitycontrol/qc-ppbpemda/"+idptsp);
        // this.$router.push({ name: "qc-ppbpemda" });
      } else if (this.user.id_role === "pendamping_qc") {
        this.$router.push("/qualitycontrol/detail-qc-ppbpemda/"+idptsp);
      } else if (
        this.user.id_role === "super_user" ||
        this.user.id_role === "helpdesk" || this.user.id_role === "dpb"
      ) {
        this.$router.push("/qualitycontrol/detail-qc-ppbpemda/"+idptsp);
      }
    },
    loaddata() {
      if (this.user.id_role === "qc") {
        axios
          .get(
            this.url +
              "/rekapitulasi_qc_pemda?filter=tahun,=,2024;perlu_qc,=,true;jenis_pemda,=,Provinsi;id_tim_qc,=," +
              this.user.id,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.totskor = response.data.data;
            // console.log(response.data)
            this.initDatatable();
          })
          .catch((error) => {
            return error;
          });
      } else if (this.user.id_role === "pendamping_qc") {
        axios
          .get(
            this.url +
              "/rekapitulasi_qc_pemda?filter=tahun,=,2024;perlu_qc,=,true;jenis_pemda,=,Provinsi;id_pendamping_qc,=," +
              this.user.id,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.totskor = response.data.data;
            // console.log(response.data)
            this.initDatatable();
          })
          .catch((error) => {
            return error;
          });
      } else if (
        this.user.id_role === "super_user" ||
        this.user.id_role === "helpdesk" || this.user.id_role === "dpb"
      ) {
        axios
          .get(
            this.url +
              "/rekapitulasi_qc_pemda?filter=tahun,=,2024;perlu_qc,=,true;jenis_pemda,=,Provinsi",
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.totskor = response.data.data;
            // console.log(response.data)
            this.initDatatable();
          })
          .catch((error) => {
            return error;
          });
      }
    },
    format_desimal(value) {
      if (value) {
        return value.replace(".", ",");
      }
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          scrollX: true,
          fixedColumns: {
            left: 2,
          },
          dom: "Bfrtip",
          // "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          //     //debugger;
          //     var index = iDisplayIndexFull + 1;
          //     $("td:first", nRow).html(index);
          //     return nRow;
          //   },
          // columnDefs: [{
          //   targets: 0,
          //   searchable: false,
          //   orderable: false,
          //   render: function(data, type, row, meta) {
          //   console.log(meta.row);
          //   // console.log(type == 'export'? meta.row : data);
          //   //return type == 'export'? meta.row + 1 : data;
          //   return meta.row + 1
          //   }
          // }],
          buttons: [
            {
              extend: "excelHtml5",
              title: "Nilai Quality Control Pemda Provinsi",
              text: '<i class="fa fa-file-excel-o"></i>&nbsp;&nbsp;Excel',
              className: "btn btn-success",
              exportOptions: {
                columns: [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,18,19,20,21,22,23
                ],
                orthogonal: "export",
                format: {
                  header: function (data, columnIdx) {
                    if (
                      columnIdx == 2 ||
                      columnIdx == 3 ||
                      columnIdx == 4 ||
                      columnIdx == 5 ||
                      columnIdx == 6
                    ) {
                      return data + " Tahun 2022";
                    } else if (
                      columnIdx == 7 ||
                      columnIdx == 8 ||
                      columnIdx == 9 ||
                      columnIdx == 10 ||
                      columnIdx == 11
                    ) {
                      return data + " Tahun 2023";
                    }else if (
                      columnIdx == 12 ||
                      columnIdx == 13 ||
                      columnIdx == 14 ||
                      columnIdx == 15 ||
                      columnIdx == 16
                    ) {
                      return data + " Tahun 2024";
                    } else if (
                      columnIdx == 19 ||
                      columnIdx == 20 ||
                      columnIdx == 21 ||
                      columnIdx == 22 ||
                      columnIdx == 23
                    ) {
                      return data + " Hasil Quality Control";
                    } else {
                      return data;
                    }
                  },
                  body: function (data, row, column, node) {
                    data = $("<p>" + data + "</p>").text();
                    return data.replace(",", ".");
                  },
                },
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();

                return "Nilai Quality Control Pemda Provinsi " + date;
              },
            },
            // {
            //     extend: 'pdf',
            //     text: 'PDF',
            //     orientation: 'landscape',
            //     title: 'NILAI QUALITY CONTROL PEMDA PROVINSI KEMENTERIAN INVESTASI/BKPM',
            //     exportOptions: {
            //         columns: [0, 1, 2, 3, 4, 5, 6,7,8,9,10,11,12,13,14,15,16,17]
            //     },
            //     filename: function () {
            //         var today = new Date();
            //         // var n = d.getTime();
            //         var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

            //         return 'Nilai Quality Control Pemda Provinsi ' + date;
            //     },
            // }
          ],
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"],
          ],
          // order: [[17, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        // t.on( 'order.dt search.dt', function () {
        // t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        // } ).draw();
        // t.on("order.dt search.dt", function () {
        //   let i = 1;

        //   t.cells(null, 0, { search: "applied", order: "applied" }).every(
        //     function (cell) {
        //       this.data(i++);
        //     }
        //   );
        // }).draw();
      }, 300);
    },
    urlpm(kode_wilayah) {
      let routeData = this.$router.resolve({
        path: `/rekapitulasi/detailpm/${kode_wilayah}`,
        // query: {'dataParameter': kode_wilayah}
      });
      window.open(routeData.href, "_blank");
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlppb(kode_wilayah) {
      let routeData = this.$router.resolve({
        path: `/rekapitulasi/detailppb/${kode_wilayah}`,
        // query: {'dataParameter': kode_wilayah}
      });
      window.open(routeData.href, "_blank");
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlhipmi(kode_wilayah) {
      let routeData = this.$router.resolve({
        path: `/rekapitulasi/detailhipmi/${kode_wilayah}`,
        // query: {'dataParameter': kode_wilayah}
      });
      window.open(routeData.href, "_blank");
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  created() {
    //   this.getuser(this.user)
    // this.getlocaldata()
  },
};
</script>
  